<div
    id="root"
    [class.has-notification-bar]="hasNotificationBar"
    [class.has-primary-nav]="hasPrimaryNav"
    [appshell-deactivate-area]="[AppShellAreaType.SidebarLocalLeft]"
    [priva-toggle-class]="{ contentActions: 'has-content-overlay' }"
    [priva-toggle-deactivate]="['contentActions', 'showPrimaryNavItems']"
>
    <!-- AREA | COMPONENT: Notification messages -->
    <priva-notifications />

    <!-- App Shell main component -->
    <router-outlet />

    <!-- Global error handling -->
    @if (error$ | async; as appError) {
        <priva-status-global class="app__error-page" [subtitle]="appError.subTitle" [title]="appError.title">
            <button
                data-footer
                [attr.id]="appError.actionHtmlId"
                [ngClass]="appError.actionHtmlClass"
                priva-button
                (click)="dispatchAction(appError.action)"
            ></button>
        </priva-status-global>
    }
</div>
