import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AppGuardData } from './app.guard.model';
import { AppCurrentUserService } from './common/authorization/current-user.service';

/**
 * This routing guard is used to check if the current user has
 * the required permissions to access the application.
 */
@Injectable({
    providedIn: 'root',
})
export class AppGuard {
    constructor(
        private readonly router: Router,
        private readonly appCurrentUserService: AppCurrentUserService,
    ) {}

    static routeData(data: AppGuardData): AppGuardData {
        return data;
    }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const routeData = route.data.appGuard as AppGuardData;

        return this.appCurrentUserService.hasPermission(routeData.permission).pipe(
            map((hasPermission) => {
                if (!hasPermission) {
                    return this.router.parseUrl(routeData.redirectUrl);
                } else {
                    return true;
                }
            }),
        );
    }
}
