import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AppConfiguration } from './app-configuration.model';

@Injectable()
export class AppConfigurationService {
    // Configuration is not compatible with app shell. Too much required
    private config: AppConfiguration = environment.configuration as any;
    private configurationLoaded$: ReplaySubject<boolean>;

    constructor() {
        this.configurationLoaded$ = new ReplaySubject<boolean>(1);
    }

    public get configuration(): AppConfiguration {
        return this.config;
    }

    public loadConfiguration() {
        this.configurationLoaded$.next(!!this.config);
    }

    public get configurationLoaded(): Observable<boolean> {
        return this.configurationLoaded$.asObservable();
    }
}
