import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { filter, mapTo, tap } from 'rxjs/operators';

import { PrimaryNavigationService } from '@priva/appshell';

import { AppPrimaryNavigationService } from '../common/routing/primary-navigation.service';
import { initializeApp, logOut, resetState } from './app.actions';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    public initialized$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initializeApp),
                tap(() => {
                    (this.navigationService as AppPrimaryNavigationService).navigation.next([
                        {
                            label: 'Companies',
                            label_short: 'companies',
                            slug: '',
                            url: `/company`,
                        },
                        {
                            label: 'Gateways',
                            label_short: 'gateways',
                            slug: '',
                            url: `/gateway`,
                        },
                    ]);
                }),
            );
        },
        { dispatch: false },
    );

    public logOut$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(logOut),
                tap(() => {
                    this.router.navigate(['signout']);
                }),
            );
        },
        { dispatch: false },
    );

    public routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            mapTo(resetState()),
        );
    });

    constructor(
        private actions$: Actions,
        private router: Router,
        private navigationService: PrimaryNavigationService,
    ) {}
}
