import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DynamicComponent } from '@priva/components/dynamic';

import { AppState } from './state/app.state';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMainComponent {
    public activeDialogs$: Observable<DynamicComponent[]>;
    public activePanel$: Observable<DynamicComponent | undefined>;

    /* istanbul ignore next, simple select */
    constructor(private store: Store<{ app: AppState }>) {
        this.activeDialogs$ = this.store.select((s) => s.app.activeDialogs);
        this.activePanel$ = this.store.select((s) => s.app.activePanel);
    }
}
