<appshell-main>
    @for (activeDialog of activeDialogs$ | async; track activeDialog) {
        <priva-dynamic-component-slot
            class="priva-dynamic-component"
            data-dynamic-slot
            [dynamicComponent]="activeDialog"
        />
    }

    <priva-dynamic-component-slot
        class="priva-dynamic-component"
        data-dynamic-slot
        [dynamicComponent]="activePanel$ | async"
    />
</appshell-main>
<priva-scrollbar-buoy />
