import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { PrivaAuthCurrentUserService } from '@priva/auth/current-user';

import {
    UserFeaturePrivaWebSupportManageDevices,
    UserFeaturePrivaWebSupportRead,
} from './current-user.constants';

export enum UserPermission {
    ManageDevices,
    Read,
}

@Injectable({
    providedIn: 'root',
})
export class AppCurrentUserService {
    constructor(private readonly privaAuthCurrentUserService: PrivaAuthCurrentUserService) {}

    /**
     * Determines whether the user has the required permission.
     * @param userPermission The permission to check.
     * @returns True if the user has the required permission, false otherwise.
     */
    hasPermission(userPermission: UserPermission): Observable<boolean> {
        return this.privaAuthCurrentUserService.user.pipe(
            map((user) => {
                switch (userPermission) {
                    case UserPermission.ManageDevices:
                        return (
                            user.features?.some(
                                (feature) => feature.name === UserFeaturePrivaWebSupportManageDevices,
                            ) ?? false
                        );
                    case UserPermission.Read:
                        return (
                            user.features?.some(
                                (feature) => feature.name === UserFeaturePrivaWebSupportRead,
                            ) ?? false
                        );
                    default:
                        return false;
                }
            }),
        );
    }
}
