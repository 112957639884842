import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Action, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { AppShellAreaService, AppShellAreaType } from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';

import { initializeApp } from 'app/state/app.actions';
import { environment } from 'src/environments/environment';

import { AppError } from './app.model';
import { UserStateService } from './shared/services/internal/user-state/user-state.service';
import { AppState } from './state/app.state';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    private unsubscribe$: Subject<void> = new Subject();
    public error$: Observable<AppError | undefined>;

    constructor(
        private store: Store<{ app: AppState }>,
        private notificationService: PrivaNotificationsService,
        private appShellAreaService: AppShellAreaService,
        private titleService: Title,
        private privaLocalizationService: PrivaLocalizationService,
        private readonly userStateService: UserStateService,
    ) {
        this.error$ = this.store.select((s) => s.app.error);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public async ngOnInit() {
        this.titleService.setTitle(environment.configuration.name);
        this.store.dispatch(initializeApp());

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // This line makes sure a language is set before the app is loaded.
        // Otherwise, the localizations may not be loaded.
        this.privaLocalizationService.language.pipe(first()).subscribe();

        this.userStateService.clearGatewayExpiredRecentlyViewed();
        this.userStateService.clearTenantExpiredRecentlyViewed();
    }

    public dispatchAction(action: Action) {
        this.store.dispatch(action);
    }
}
