export interface UserState {
    version: number;
    gateway: EntityGroup;
    tenant: EntityGroup;
}

export enum EntityGroupType {
    Gateway,
    Tenant,
}

export interface EntityGroup {
    favorites: EntityFavorite[];
    recentlyViewedCollection: EntityCollectionType;
    recentlyViewed: EntityRecentlyViewed[];
}

export interface Entity {
    id: string;
    name: string;
}

export enum EntityCollectionType {
    Favorite,
    RecentlyViewed,
}

export interface EntityFavorite extends Entity {}

export interface EntityRecentlyViewed extends Entity {
    date: Date;
}
