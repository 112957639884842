import { Injectable } from '@angular/core';

import { SEARCH_OVERVIEW_PAGE_SIZE } from 'app/shared/constants/search-overview.constants';
import {
    Entity,
    EntityCollectionType,
    EntityFavorite,
    EntityGroup,
    EntityGroupType,
    EntityRecentlyViewed,
    UserState,
} from 'app/shared/services/internal/user-state/user-state.model';

const LOCAL_STORAGE_USER_STATE_KEY = 'userState';
const LOCAL_STORAGE_USER_STATE_VERSION = 1;

/**
 * This service is used to store user state information which is stored in browser local storage and contains the
 * following information:
 * - Recently viewed gateways
 * - Favorite gateways
 * - Recently viewed tenants
 * - Favorite tenants
 *
 * It also stores which collection was recently viewed (favorite or recently viewed) for gateways and tenants.
 *
 * The gateway and tenant information is stored in a separate entity group.
 *
 * Note that this service may not lead to atomic results when the web app is used in multiple tabs.
 * https://stackoverflow.com/questions/22001112/is-localstorage-thread-safe
 */
@Injectable({
    providedIn: 'root',
})
export class UserStateService {
    getGatewaysFavorite(): EntityFavorite[] {
        return this.getUserState().gateway.favorites;
    }

    isGatewayFavorite(id: string): boolean {
        return this.getUserState().gateway.favorites.some((gateway) => gateway.id === id);
    }

    setGatewayFavorite(id: string, name: string, favorite: boolean): void {
        if (favorite) {
            this.addEntity(EntityGroupType.Gateway, EntityCollectionType.Favorite, {
                id,
                name,
            } as EntityFavorite);
        } else {
            this.removeEntity(EntityGroupType.Gateway, EntityCollectionType.Favorite, id);
        }
    }

    getGatewaysRecentlyViewed(): EntityRecentlyViewed[] {
        return this.getUserState().gateway.recentlyViewed;
    }

    clearGatewayExpiredRecentlyViewed(): void {
        this.clearEntityExpired(EntityGroupType.Gateway);
    }

    setGatewayRecentlyViewed(id: string, name: string, date: Date): void {
        this.addEntity(
            EntityGroupType.Gateway,
            EntityCollectionType.RecentlyViewed,
            {
                id,
                name,
                date,
            } as EntityRecentlyViewed,
            SEARCH_OVERVIEW_PAGE_SIZE,
        );
    }

    getRecentlyViewedCollection(entityGroupType: EntityGroupType): EntityCollectionType {
        const userState = this.getUserState();
        const entityGroup = this.getEntityGroup(userState, entityGroupType);

        return entityGroup.recentlyViewedCollection;
    }

    setRecentlyViewedCollection(
        entityGroupType: EntityGroupType,
        entityCollectionType: EntityCollectionType,
    ): void {
        const userState = this.getUserState();
        const entityGroup = this.getEntityGroup(userState, entityGroupType);

        entityGroup.recentlyViewedCollection = entityCollectionType;

        this.setLocalUserState(userState);
    }

    getTenantsFavorite(): EntityFavorite[] {
        return this.getUserState().tenant.favorites;
    }

    isTenantFavorite(id: string): boolean {
        return this.getUserState().tenant.favorites.some((tenant) => tenant.id === id);
    }

    setTenantFavorite(id: string, name: string, favorite: boolean) {
        if (favorite) {
            this.addEntity(EntityGroupType.Tenant, EntityCollectionType.Favorite, {
                id,
                name,
            } as EntityFavorite);
        } else {
            this.removeEntity(EntityGroupType.Tenant, EntityCollectionType.Favorite, id);
        }
    }

    getTenantsRecentlyViewed(): EntityRecentlyViewed[] {
        return this.getUserState().tenant.recentlyViewed;
    }

    clearTenantExpiredRecentlyViewed(): void {
        this.clearEntityExpired(EntityGroupType.Tenant);
    }

    setTenantRecentlyViewed(id: string, name: string, date: Date): void {
        this.addEntity(
            EntityGroupType.Tenant,
            EntityCollectionType.RecentlyViewed,
            {
                id,
                name,
                date,
            } as EntityRecentlyViewed,
            SEARCH_OVERVIEW_PAGE_SIZE,
        );
    }

    private getUserState(): UserState {
        const json = localStorage.getItem(LOCAL_STORAGE_USER_STATE_KEY);

        return json ? JSON.parse(json) : this.createUserState();
    }

    private setLocalUserState(state: UserState): void {
        const json = JSON.stringify(state);

        localStorage.setItem(LOCAL_STORAGE_USER_STATE_KEY, json);
    }

    private createUserState(): UserState {
        return {
            version: LOCAL_STORAGE_USER_STATE_VERSION,
            gateway: this.createEntityGroup(),
            tenant: this.createEntityGroup(),
        };
    }

    private createEntityGroup(): EntityGroup {
        return {
            favorites: [],
            recentlyViewedCollection: EntityCollectionType.RecentlyViewed,
            recentlyViewed: [],
        };
    }

    private getEntityGroup(userState: UserState, entityGroupType: EntityGroupType): EntityGroup {
        return entityGroupType === EntityGroupType.Gateway ? userState.gateway : userState.tenant;
    }

    private getEntityCollection(
        entityGroup: EntityGroup,
        entityCollectionType: EntityCollectionType,
    ): Entity[] {
        return entityCollectionType === EntityCollectionType.Favorite
            ? entityGroup.favorites
            : entityGroup.recentlyViewed;
    }

    private addEntity(
        entityGroupType: EntityGroupType,
        entityCollectionType: EntityCollectionType,
        entity: Entity,
        entityStorageSize: number | undefined = undefined,
    ): void {
        const userState = this.getUserState();
        const entityGroup = this.getEntityGroup(userState, entityGroupType);
        const entityCollection = this.getEntityCollection(entityGroup, entityCollectionType);
        const entityOld = entityCollection.find((entityOld) => entityOld.id === entity.id);

        // Remove the entity if it already exists
        if (entityOld) {
            entityCollection.splice(entityCollection.indexOf(entityOld), 1);
        }

        // Store the entity at the end of the collection
        entityCollection.push(entity);

        // Remove the oldest entity if the collection exceeds the maximum size
        if (entityStorageSize && entityCollection.length > entityStorageSize) {
            entityCollection.shift();
        }

        this.setLocalUserState(userState);
    }

    clearEntityExpired(entityGroupType: EntityGroupType): void {
        const userState = this.getUserState();
        const entityGroup = this.getEntityGroup(userState, entityGroupType);
        const now = new Date();
        const expirationDate = new Date(now.setMonth(now.getMonth() - 1));

        entityGroup.recentlyViewed = entityGroup.recentlyViewed.filter(
            (entity) => new Date(entity.date) >= expirationDate,
        );

        this.setLocalUserState(userState);
    }

    private removeEntity(
        entityGroupType: EntityGroupType,
        entityCollectionType: EntityCollectionType,
        entityId: string,
    ): void {
        const userState = this.getUserState();
        const entityGroup = this.getEntityGroup(userState, entityGroupType);
        const entityCollection = this.getEntityCollection(entityGroup, entityCollectionType);
        const entityOld = entityCollection.find((entityOld) => entityOld.id === entityId);

        if (entityOld) {
            entityCollection.splice(entityCollection.indexOf(entityOld), 1);
        }

        this.setLocalUserState(userState);
    }
}
